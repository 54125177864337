import React from 'react'

import Layout from '../components/Layout'
import SEO from '../components/SEO'
import Bar from '../components/Bar'
import Main from '../components/Main'

export default function NotFoundPage() {
  return (
    <Layout>
      <SEO title="404: Seite nicht gefunden" />
      <Bar color="--primary-800">
        <h2>404: Seite nicht gefunden</h2>
      </Bar>
      <Main>
        <p>Die angeforderte Seite wurde nicht gefunden.</p>
      </Main>
    </Layout>
  )
}
